import { createContext } from 'react'

import { ChainID } from 'constant'

interface ContextType<S = ChainID> {
  selectedChain: S
  setSelectedChain: (chain: S) => void
}

export const SelectChainContext = createContext<ContextType>({
  selectedChain: ChainID.A8,
  setSelectedChain: () => {},
})
