import { marketplaceApi as api } from 'services/base-axios'
import { getBlockscoutService } from 'services/blockscout'

import configs from 'configs'

import { ChainID } from 'constant'

import type { UserNFTData } from 'store/walletNFT.reducer'
import type { IUserNftResponse } from 'types/user-nft.type'
import {
  AddressNftResponse,
  NftInstance,
} from 'blockscout-cli/dist/esm/types/api/nft'
import { GetNftDetailsDto, UserNftDto } from 'types/evm.type'

const EVM_CONTROLLER = '/evm/nft'
const RONIN_CONTROLLER = '/ronin/nft'

class UserNft {
  private getMoralisNft = async (userNftDto: UserNftDto) => {
    return api
      .get(EVM_CONTROLLER, {
        params: {
          ...userNftDto,
        },
      })
      .then((data) => data.data)
  }

  private getBlockscoutNft = async (
    chainId: ChainID,
    userNftDto: UserNftDto,
  ) => {
    const blockscoutService = getBlockscoutService(chainId)

    return blockscoutService
      .getAddressTokens(configs.walletEVM.adminAddress, userNftDto?.tokenType, {
        params: userNftDto?.cursor,
      })
      .then((data) => {
        const listNft = data?.data?.items

        const result: UserNFTData[] = listNft?.map((nftDetail) => {
          const tokenInfoMeta = nftDetail.token_instance?.metadata

          return {
            amount: nftDetail.value,
            attributes: tokenInfoMeta?.attributes,
            collectionName: tokenInfoMeta?.collectionName,
            contractType: nftDetail.token.type,
            description: tokenInfoMeta?.description ?? '',
            image: nftDetail.token_instance?.image_url ?? '',
            name: tokenInfoMeta?.name ?? '',
            ownerOf: configs.walletEVM.adminAddress,
            symbol: nftDetail.token.symbol,
            tokenAddress: nftDetail.token.address,
            tokenId: nftDetail?.token_id
              ? parseInt(nftDetail.token_id)
              : undefined,
          } as UserNFTData
        })

        return {
          result,
          cursor: data.data?.next_page_params,
          chainId,
        }
      })
  }

  private getListNftByBlockscout = async (
    userNftDto: UserNftDto,
  ): Promise<AddressNftResponse> => {
    const chainId = userNftDto.chainId
    const blockscoutService = getBlockscoutService(chainId)

    const { data } = await blockscoutService.getAddressNft(
      configs.walletEVM.adminAddress,
      undefined, // Get all NFT if type(ERC-1155/ERC-721) is undefined
      { params: userNftDto?.cursor },
    )
    return data
  }

  private getRoninNft = async (userNftDto: UserNftDto) => {
    return api
      .get(RONIN_CONTROLLER, {
        params: {
          ...userNftDto,
        },
      })
      .then((data) => data.data)
  }

  async getUserNft(userNftDto: UserNftDto): Promise<IUserNftResponse> {
    const { chainId } = userNftDto

    switch (chainId) {
      case ChainID.Ronin:
        return this.getRoninNft(userNftDto)
      case ChainID.A8:
      case ChainID.Etherlink:
        return this.getBlockscoutNft(chainId, userNftDto)
      default:
        return this.getMoralisNft(userNftDto)
    }
  }

  // Get List NFT
  async getListNft(userNftDto: UserNftDto): Promise<any> {
    const { chainId } = userNftDto

    switch (chainId) {
      case ChainID.A8:
      case ChainID.Etherlink:
        return this.getListNftByBlockscout(userNftDto)
      default:
        return this.getMoralisNft(userNftDto)
    }
  }

  // Get NFT details
  async getNftDetail(chainId: ChainID, tokenInfo: GetNftDetailsDto) {
    switch (chainId) {
      case ChainID.A8:
      case ChainID.Etherlink:
        return this.getNftDetailsByBlockscout(chainId, tokenInfo)
      default:
        return
    }
  }

  async getNftDetailsByBlockscout(
    chainId: ChainID,
    dto: GetNftDetailsDto,
  ): Promise<NftInstance> {
    const blockscoutService = getBlockscoutService(chainId)
    const { tokenAddress, tokenId } = dto
    const { data } = await blockscoutService.getNftInstanceById(
      tokenAddress,
      tokenId,
    )

    return data
  }
}

export const UserNftService = new UserNft()
