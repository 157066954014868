import { useEffect, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import hash from 'object-hash'

import { UserNFTData } from 'store/walletNFT.reducer'
import { UserNftService } from 'services/marketplace/evm/userNft'

import configs from 'configs'

import { ChainID } from 'constant'

import { Web3Type } from 'types/web3-item.type'

type GetNftsInfiniteDto = {
  chainId: ChainID
}

export const useNftsInfinite = ({ chainId }: GetNftsInfiniteDto) => {
  const [nftMap, setNftMap] = useState<Record<string, UserNFTData>>()

  const { data, ...rest } = useInfiniteQuery({
    queryKey: ['GET-NFTS-INFINITE', chainId],
    queryFn: async ({ pageParam }) => {
      const data = await UserNftService.getListNft({
        chainId,
        page: pageParam?.page,
        extra: pageParam?.extra,
        cursor: pageParam?.cursor,
      })

      const listNft = data.items
      const result: UserNFTData[] = listNft?.map((nftDetail: any) => {
        const tokenInfoMeta = nftDetail?.metadata
        return {
          amount: nftDetail.value,
          attributes: tokenInfoMeta?.attributes,
          collectionName: tokenInfoMeta?.collectionName,
          contractType: nftDetail.token_type,
          description: tokenInfoMeta?.description,
          image: tokenInfoMeta?.image || nftDetail?.image_url || '',
          name: tokenInfoMeta?.name || nftDetail?.token?.name || '',
          ownerOf: configs.walletEVM.adminAddress,
          symbol: nftDetail?.token?.symbol ?? '',
          tokenAddress: nftDetail?.token?.address,
          tokenId: nftDetail?.id !== null ? parseInt(nftDetail?.id) : undefined,
          tokenStandard: nftDetail?.token_type,
        } as UserNFTData
      })
      return {
        result,
        chainId,
        cursor: data?.next_page_params,
        page: data?.page,
        extra: data?.extra,
      }
    },
    enabled: !!chainId,
  })

  useEffect(() => {
    const nftList = data?.pages.flatMap((page) => page.result) ?? []

    const nftMapTemp = nftList.reduce((acc, cur) => {
      const nftData = {
        chainId,
        web3Type: Web3Type.NFT,
        blockData: {
          tokenId: cur.tokenId || '',
          tokenAddress: cur.tokenAddress || '',
          srcAddress: cur.ownerOf || '',
          contractType: cur.contractType || '',
          collectionName: cur.collectionName || '',
          symbol: cur.symbol || '',
        },
      }

      const nftUniqueId = `${cur.tokenId}-${cur.tokenAddress}`
      const web3ItemId = hash(nftData)

      acc[nftUniqueId] = { ...cur, web3ItemId }

      return acc
    }, {} as Record<string, UserNFTData>)

    setNftMap(nftMapTemp)
  }, [chainId, data])

  return { ...rest, data: nftMap ?? {} }
}
