import { useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Avatar, Select, Space, Typography } from 'antd'
import IonIcon from '../ionIcon'

import { SelectChainContext } from './selectChainContext'

import { ChainID, OptionElement } from 'constant'

import solana from 'static/images/logoChain/solana.png'
// import binance from 'static/images/logoChain/binance.png'
import ethereum from 'static/images/logoChain/ethereum.png'
import arbitrum from 'static/images/logoChain/arbitrum.png'
import polygon from 'static/images/logoChain/polygon-logo.png'
import ron from 'static/images/logoChain/ron.png'
import a8 from 'static/images/logoChain/a8.png'
import optimism from 'static/images/logoChain/optimism.png'
import base from 'static/images/logoChain/base.png'
import etherlink from 'static/images/logoChain/etherlink.png'

export const CHAIN_OPTIONS: OptionElement[] = [
  new OptionElement(
    (
      <Space>
        <Avatar src={solana} size={24} />
        <Typography.Text>Solana</Typography.Text>
      </Space>
    ),
    ChainID.Solana,
    true,
  ),
  // new OptionElement(
  //   (
  //     <Space>
  //       <Avatar src={binance} size={24} />
  //       <Typography.Text>Binance Smart Chain</Typography.Text>
  //     </Space>
  //   ),
  //   ChainID.BSC,
  // ),
  new OptionElement(
    (
      <Space>
        <Avatar src={a8} size={24} />
        <Typography.Text>A8</Typography.Text>
      </Space>
    ),
    ChainID.A8,
  ),
  new OptionElement(
    (
      <Space>
        <Avatar src={ethereum} size={24} />
        <Typography.Text>Ethereum</Typography.Text>
      </Space>
    ),
    ChainID.Ethereum,
  ),
  new OptionElement(
    (
      <Space>
        <Avatar src={arbitrum} size={24} />
        <Typography.Text>Arbitrum</Typography.Text>
      </Space>
    ),
    ChainID.Arbitrum,
  ),
  new OptionElement(
    (
      <Space>
        <Avatar src={polygon} size={24} />
        <Typography.Text>Polygon</Typography.Text>
      </Space>
    ),
    ChainID.Polygon,
  ),
  new OptionElement(
    (
      <Space>
        <Avatar src={ron} size={24} />
        <Typography.Text>Ronin</Typography.Text>
      </Space>
    ),
    ChainID.Ronin,
  ),
  new OptionElement(
    (
      <Space>
        <Avatar src={optimism} size={24} />
        <Typography.Text>Optimism</Typography.Text>
      </Space>
    ),
    ChainID.Optimism,
  ),
  new OptionElement(
    (
      <Space>
        <Avatar src={base} size={24} />
        <Typography.Text>BASE</Typography.Text>
      </Space>
    ),
    ChainID.Base,
  ),
  new OptionElement(
    (
      <Space>
        <Avatar src={etherlink} size={24} />
        <Typography.Text>Etherlink</Typography.Text>
      </Space>
    ),
    ChainID.Etherlink,
  ),
]

const SelectChain = ({
  disabled,
  chainId,
}: {
  disabled?: boolean
  chainId?: ChainID
}) => {
  const params = useParams()
  const { selectedChain, setSelectedChain } = useContext(SelectChainContext)

  const isEdit = useMemo(() => Object.keys(params).length > 0, [params])

  const sortChain = (chainA: OptionElement, chainB: OptionElement) => {
    return chainA.value > chainB.value ? 1 : -1
  }

  useEffect(() => {
    isEdit && chainId && setSelectedChain(chainId)
  }, [isEdit, chainId, setSelectedChain])

  return (
    <Select
      value={selectedChain}
      bordered={false}
      size="small"
      style={{ width: 200, background: 'transparent', padding: 0 }}
      options={CHAIN_OPTIONS.sort(sortChain)}
      suffixIcon={
        <IonIcon
          style={{ fontSize: 18, fontWeight: 700, color: '#42bbb0' }}
          name="chevron-down-outline"
        />
      }
      className="select-chain"
      onSelect={(chain) => {
        setSelectedChain(chain)
      }}
      disabled={disabled}
    />
  )
}

export default SelectChain
