import { ChainID } from 'constant'
import { VerificationMethod } from 'types/task.type'
import { TokenStandard } from 'types/token.type'

export interface ButtonCreateProps {
  onClick: (type: TaskType, config: MapConfigs[typeof type]) => void
  dynamicConfigs?: SocialTemplateConfigs | PartnerTemplateConfigs
}

export const CREATE_TASK_KEY = 'createTask::'

export interface FormCreateProps {
  taskInfo: TaskInfo
  onChange: (taskInfo: TaskInfo) => void
}

export interface PreviewTaskProps {
  taskId: string
  onTrack: () => void
  loading: boolean
  completed: boolean
}

export type TemplateConfigs<T extends TaskType> = {
  type: T
  title: string
  thumbnail: string
  defaultData: MapConfigs[T]
  color?: string
}

export type SocialTemplateConfigs = TemplateConfigs<TaskType.Social> & {
  extra: Record<string, ExtraFields>
}

export type PartnerTemplateConfigs = TemplateConfigs<TaskType.Partner> & {
  extra: Record<string, ExtraFields>
}

export type ExtraFields = {
  componentType: ExtraFieldComponentTypes
  extraFieldTitle: string
  extraFieldPlaceholder: string
}

export type ExtraFieldComponentTypes = 'Input' | 'Select' | 'MarkdownEditor'

export type SocialTaskAction =
  | TwitterTaskAction
  | DiscordTaskAction
  | TelegramTaskAction
  | YoutubeTaskAction
  | FacebookTaskAction

export type PartnerTaskAction =
  | ApeironTaskAction
  | OccupyWallStreetTaskAction
  | CommonPartnerTaskAction

export enum TaskKeys {
  SystemDiscord = 'system-connect-discord',
  SystemTwitter = 'system-connect-twitter',
  SystemWallet = 'system-connect-wallet',
  SystemRoninWallet = 'system-connect-ronin-wallet',
  BridgeToken = 'bridge-token',
  Transaction = 'transaction',
  InteractWithContract = 'interact-with-contract',
  TokenSpent = 'tokenspent',
  USDSpent = 'usdspent',
  Twitter = 'flexibletask-twitter',
  Telegram = 'flexibletask-telegram',
  Discord = 'flexibletask-discord',
  Custom = 'flexibletask-custom',

  /* Social task keys naming rules: social-{provider}-{action}
  This rules is used to generate taskkey from task configs in 'useTaskKey' hook */
  LikeTwitter = 'social-twitter-like',
  FollowTwitter = 'social-twitter-follow',
  RetweetTwitter = 'social-twitter-retweet',
  QuoteTwitter = 'social-twitter-quote',
  ReplyTwitter = 'social-twitter-reply',
  JoinDiscord = 'social-discord-join',
  VerifyRoleDiscord = 'social-discord-verify_role',
  JoinTelegram = 'social-telegram-join',
  FollowFacebookPage = 'social-facebook-follow_page',
  LikeFacebookPage = 'social-facebook-like_page',
  LikeFacebookPost = 'social-facebook-like_post',
  ShareFacebookPost = 'social-facebook-share_post',
  PostFacebookTags = 'social-facebook-post_tags',
  JoinFacebookGroup = 'social-facebook-join_group',
  SubscribeYoutube = 'social-youtube-subscribe',

  //Question
  QuestionAnswer = 'question-answer',
  QuestionQuiz = 'question-quiz',

  /* Partner task keys naming rules: `partner-{provider}-{action}` 
  This rules is used to generate taskkey from task configs in 'useTaskKey' hook */
  ApeironPlayTime = 'partner-apeiron-playtime',
  OWSRegisteredGame = 'partner-occupywallstreet-registered',
  CustomizeVerifyAPI = 'partner-commonpartner-customize_verify_api',

  //Federated api
  FederatedApi = 'federatedapi',
}

export const SocialTaskKeys: TaskKeys[] = [
  TaskKeys.LikeTwitter,
  TaskKeys.FollowTwitter,
  TaskKeys.RetweetTwitter,
  TaskKeys.QuoteTwitter,
  TaskKeys.ReplyTwitter,
  TaskKeys.JoinDiscord,
  TaskKeys.VerifyRoleDiscord,
  TaskKeys.JoinTelegram,
  TaskKeys.FollowFacebookPage,
  TaskKeys.LikeFacebookPage,
  TaskKeys.LikeFacebookPost,
  TaskKeys.ShareFacebookPost,
  TaskKeys.PostFacebookTags,
  TaskKeys.JoinFacebookGroup,
  TaskKeys.SubscribeYoutube,
]

export const PartnerTaskKeys: TaskKeys[] = [
  TaskKeys.ApeironPlayTime,
  TaskKeys.OWSRegisteredGame,
  TaskKeys.CustomizeVerifyAPI,
]

export type TaskInfo = {
  taskId: string
  title: string
  description: string
  configs: any // TODO: update type later
  verificationMethod: VerificationMethod
  isPublished?: boolean
  isOptional?: boolean
}

/**
 * Custom task configs
 */
export type TaskHoldConfigs = {
  chain: ChainID
  mint: string
  amount: string
  fromDate: Date
  toDate: Date
  action: string
}

export type TaskTradeConfigs = {
  chain: ChainID
  mint: string
  tokenAccountTracking: string
  amount: string
  fromDate: Date
  toDate: Date
}

export type TaskBridgeConfigs = {
  fromChain: ChainID
  toChain: ChainID
  fromDate: Date
  toDate: Date
  action: string
  url: string
  bridgeAddress: string
}

export type TaskSystemConfigs = {
  action:
    | 'connect-ronin-wallet'
    | 'connect-wallet'
    | 'connect-twitter'
    | 'connect-gmail'
    | 'connect-discord'
}

export type TaskFederatedApiConfigs = {
  federatedApiId?: string
  headers: string[]
  pattern: string
}

export enum SocialTaskProvider {
  Facebook = 'TASK_PROVIDER:FACEBOOK',
  Twitter = 'TASK_PROVIDER:TWITTER',
  Discord = 'TASK_PROVIDER:DISCORD',
  Telegram = 'TASK_PROVIDER:TELEGRAM',
  Youtube = 'TASK_PROVIDER:YOUTUBE',
}

export enum PartnerTaskProvider {
  Apeiron = 'TASK_PROVIDER:APEIRON',
  OccupyWallStreet = 'TASK_PROVIDER:OCCUPYWALLSTREET',
  CommonPartner = 'TASK_PROVIDER:COMMON_PARTNER',
}

export enum TaskType {
  FlexibleTask = 'FlexibleTask',
  Hold = 'Hold',
  Bridge = 'Bridge',
  Trade = 'Trade',
  System = 'System',
  Social = 'Social',
  Question = 'Question',
  Partner = 'Partner',
  Transaction = 'Transaction',
  InteractWithContract = 'interact-with-contract',
  FederatedApi = 'FederatedApi',
  TokenSpent = 'TokenSpent',
  USDSpent = 'USDSpent',
}

export enum TwitterTaskAction {
  Follow = 'TWITTER:FOLLOW',
  Like = 'TWITTER:LIKE',
  Retweet = 'TWITTER:RETWEET',
  Quote = 'TWITTER:QUOTE',
  Reply = 'TWITTER:REPLY',
}

export enum FacebookTaskAction {
  Follow = 'FACEBOOK:FOLLOW',
  LikePage = 'FACEBOOK:LIKE_PAGE',
  LikePost = 'FACEBOOK:LIKE_POST',
  SharePost = 'FACEBOOK:SHARE_POST',
  PostTags = 'FACEBOOK:POST_TAGS',
  JoinGroup = 'FACEBOOK:JOIN_GROUP',
}

export enum TelegramTaskAction {
  Join = 'TELEGRAM:JOIN',
}

export enum DiscordTaskAction {
  Join = 'DISCORD:JOIN',
  VerifyRole = 'DISCORD:VERIFY_ROLE',
}

export enum YoutubeTaskAction {
  Subscribe = 'YOUTUBE:SUBSCRIBE',
  Join = 'YOUTUBE:JOIN',
}

export enum QuestionAnswerTaskAction {
  Answer = 'answer',
}

export enum QuestionQuizTaskAction {
  Quiz = 'quiz',
}

export enum ApeironTaskAction {
  PlayTime = 'APEIRON:PLAYTIME',
}

export enum OccupyWallStreetTaskAction {
  RegisteredGame = 'OCCUPYWALLSTREET:REGISTERED',
}

export enum CommonPartnerTaskAction {
  CustomizeVerifyAPI = 'COMMONPARTNER:CUSTOMIZE_VERIFY_API',
}

export type TaskFlexibleConfigs = {
  url: string
  action: string
}

export type TaskSocialConfigs = {
  provider: SocialTaskProvider
  action: SocialTaskAction
  meta?: any
}

export type TaskQuestionConfigs = {
  action: QuestionAnswerTaskAction.Answer | QuestionQuizTaskAction.Quiz
  question?: string
  meta?: any
}

export type TaskPartnerConfigs = {
  provider: PartnerTaskProvider
  action: PartnerTaskAction
  meta?: any
}

export type TaskTransactionConfigs = {
  chain: ChainID
  fromDate: Date
  toDate: Date
  numberOfTransaction: number
  url: string
}

export type InteractWithContractConfigs = {
  chain: ChainID
  fromDate?: Date
  toDate?: Date
  inboundContractAddress: string
  functionName: string
  methodId?: string
  numberOfTxs: number
}

export type TaskTokenSpentConfigs = {
  chain: ChainID
  tokenType: TokenStandard
  tokenAddress: string
  fromDate: Date
  toDate: Date
  contractAddress: string
  amount: number
  tokenId?: number
  side?: Side
}

export type TaskUSDSpentConfigs = TaskTokenSpentConfigs

export type Side = 'from' | 'to'

export type TaskConfigs =
  | TaskHoldConfigs
  | TaskTradeConfigs
  | TaskBridgeConfigs
  | TaskSystemConfigs
  | TaskFlexibleConfigs
  | TaskSocialConfigs
  | TaskQuestionConfigs
  | TaskPartnerConfigs
  | TaskTransactionConfigs
  | InteractWithContractConfigs
  | TaskFederatedApiConfigs
  | TaskTokenSpentConfigs
  | TaskUSDSpentConfigs

export type MapConfigs = {
  [TaskType.System]: TaskSystemConfigs
  [TaskType.FlexibleTask]: TaskFlexibleConfigs
  [TaskType.Hold]: TaskHoldConfigs
  [TaskType.Trade]: TaskTradeConfigs
  [TaskType.Social]: TaskSocialConfigs
  [TaskType.Question]: TaskQuestionConfigs
  [TaskType.Partner]: TaskPartnerConfigs
  [TaskType.Bridge]: TaskBridgeConfigs
  [TaskType.Transaction]: TaskTransactionConfigs
  [TaskType.InteractWithContract]: InteractWithContractConfigs
  [TaskType.FederatedApi]: TaskFederatedApiConfigs
  [TaskType.TokenSpent]: TaskTokenSpentConfigs
  [TaskType.USDSpent]: TaskUSDSpentConfigs
}
